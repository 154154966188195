<template>
  <div class="register-content">
    <div class="content">
      <div class="decoration1"></div>
      <div class="decoration2"></div>
      <div class="decoration3"></div>
      <div>
        <img
          class="title"
          src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/title.png"
        />
        <p class="fz-16 mt-56 ml-120">请选择：</p>
        <div class="btn mt-16" @click="handleRouteTo('wlhy')">网络货运企业</div>
        <div class="btn mt-32" @click="handleRouteTo">非网络货运企业</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "Register",
  components: {},

  setup() {
    const router = useRouter();
    const handleRouteTo = tag => {
      router.push({ name: "FillInfo", query: tag ? { wlhy: 1 } : {} });
    };

    return { handleRouteTo };
  }
};
</script>

<style lang="less" scoped>
.register-content {
  background: url("https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/register_bg.jpg")
    no-repeat center center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  .content {
    width: 640px;
    height: 482px;
    opacity: 1;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .decoration1 {
      width: 64px;
      height: 120px;
      opacity: 1;
      background: rgba(0, 122, 255, 0.5);
      border: 1px solid rgba(176, 212, 255, 0.38);
      border-radius: 8px 0 0 8px;
      backdrop-filter: blur(6px);
      position: absolute;
      left: -64px;
    }
    .decoration2 {
      width: 48px;
      height: 48px;
      opacity: 1;
      background: rgba(255, 116, 0, 0.5);
      border: 1px solid;
      border-image: linear-gradient(
          135deg,
          #ffb677 49%,
          rgba(255, 193, 140, 0) 100%,
          #fbc79a 100%
        )
        0.5 0.5;
      border-radius: 4px;
      backdrop-filter: blur(10px);
      position: absolute;
      left: -24px;
      top: -24px;
    }
    .decoration3 {
      width: 40px;
      height: 40px;
      opacity: 1;
      background: rgba(255, 116, 0, 0.5);
      border: 1px solid;
      border-image: linear-gradient(
          135deg,
          #ffb677 41%,
          rgba(255, 193, 140, 0) 100%,
          #fbc79a 100%
        )
        0.5 0.5;
      border-radius: 4px;
      backdrop-filter: blur(10px);
      position: absolute;
      bottom: -20px;
      right: -20px;
    }
    .title {
      width: 356px;
      display: block;
      margin: 56px auto 0;
    }
    .ml-120 {
      margin-left: 120px;
      color: #4d4d4d;
    }
    .btn {
      width: 400px;
      height: 88px;
      background: #f9f9f9;
      border-radius: 8px;
      margin: 0 auto;
      font-size: 20px;
      font-weight: 600;
      line-height: 88px;
      text-align: center;
      color: #4d4d4d;
      &:hover {
        background: #ffffff;
        box-shadow: 0px 0px 16px 0px rgba(22, 132, 251, 0.1);
        color: #1684fb;
        cursor: pointer;
      }
      &:active {
        background: #1684fb;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
